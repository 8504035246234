import React, { useState, useEffect } from 'react';
import { APP_NAME } from '../../config/config';
import useCurrentUser from '../../resources/user/useCurrentUser';
import BasePage from '../../templates/pages/BasePage';
import DateBlockWithList from '../../components/dashboard-widget';

const IP_STATUS_URL = 'http://192.168.1.56/';

const Dashboard = () => {
    const { user: { profile } } = useCurrentUser();

    return (
        <BasePage
            title={ 'Dashboard' }
            breadcrumb={ 'Dashboard' }
        >
            <h2 className={ 'text-lg font-medium mb-4' }>Hoi { profile?.name }, welkom bij { APP_NAME }!</h2>
            <div className="my-4 py-2">
                Status van de NUC computer kun je testen door naar de volgende url te gaan: <a href="http://192.168.1.56/" target="_blank" rel="noreferrer">http://192.168.1.56/</a>.<br/>
                Als deze url `&quot;message&quot;:&quot;&quot;` toont, is hij online. Is hij offline, dan kunnen er geen doosstickers geprint worden.
            </div>
            <hr/>

            <div className="my-4 py-2">
                <h3 className={ 'text-lg font-medium mb-4' }>Laatste updates</h3>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <DateBlockWithList
                    date="03-04"
                    year="2025"
                    items={[
                        'Overview wijzigingen om overzicht van best lopende producten op basis van aantallen aangepast',
                        'Statistieken bij Product laat nu gegevens versie-breed zien',
                        '2e statistiek laten zien bij Product met hierin het aantal per verpakking',
                        'Voorraadwaarde export aangepast voor Gerry'
                    ]}
                  />
                  <DateBlockWithList
                    date="27-03"
                    year="2025"
                    items={[
                        'Filter op Voorraadwaarde verwijderd zodat hij dezelfde informatie toont als Voorraadoverzicht',
                        'React-tooltip geimplementeerd, dus een betere variant van de tooltips die nu altijd tonen',
                        'Nieuw tabblad Overview gemaakt, dit is een verzameltabblad van allemaal tabellen in één',
                        'ChartJS geimplementeerd bij Producten - Show. Begin gemaakt voor de weergave van statistieken bij Producten',
                        'Op het Dashboard een status indicator gemaakt voor de NUC. Afhankelijk hiervan kunnen we zien of de computer online/offline is en of we kunnen printen',
                        'Op het Dashboard zien we nu de laatste updates zodat we bij kunnen houden wanneer er wat gedaan is',
                    ]}
                  />
                  <DateBlockWithList
                    date="14-03"
                    year="2025"
                    items={[
                        '⁠Gira PDF werkt weer in service.TP.nl',
                        '⁠Sommige klanten detail pagina’s deden het niet, die werken nu wel weer',
                        '⁠Doossticker design fixes, nog niet helemaal naar wens, dus Work In Progress',
                        '⁠Doossticker download link gefixed',
                        '⁠Tooltip voor de actie buttons gefixed, teksten worden nog vertaald',
                        '⁠Aantal archiveer/verwijderd buttons verwijderd, deze zijn ook te bereiken via de detail pagina',
                    ]}
                  />
                </div>

            </div>

        </BasePage>
    );

};

export default Dashboard;