import React, { FC, useMemo } from 'react';
// import OverviewForm from './OverviewForm';
import { OverviewModel, OverviewRelationsBluePrint } from './OverviewTypes';
import useOverview from './useOverview';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';

type Props = Required<ExtendTableProps>

const OverviewTable: FC<Props> = () => {

    const httpHook = useOverview();

    type ExclusionItem = {
        key: 'exclude_cn' | 'exclude_zwik';
        label: string;
    };
    const exclusions: ExclusionItem[] = [
        { key: 'exclude_cn', label: 'Zonder Clean Nutrition' },
        { key: 'exclude_zwik', label: 'Zonder Zwik Supplementen' }
    ];

    const rows = useMemo((): TableColumns<OverviewModel, OverviewRelationsBluePrint>[] => [
      
        {
            header: {
                children: 'Product',
            },
            column: (item) => ({
                children: item.name,
                linkTo: (item) => `/products/${item.product_id}`
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Hoeveelheid in stuks',
                sortCol: 'total_article_amount'
            },
            column: (item) => ({
                children: item.total_article_amount
            }),
            type: 'numeric',
        },
        {
            header: {
                children: 'Hoeveelheid orders',
                sortCol: 'total_sales'
            },
            column: (item) => ({
                children: item.total_sales
            }),
            type: 'numeric',
        },
        {
            header: {
                children: 'Totaal omzet',
                sortCol: 'price_per_amount'
            },
            column: (item) => ({
                children: item.price_per_amount
            }),
            type: 'currency',
        },
    ], []);

    return (<>
        <Table
            id={ 'Overview' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [ 'id', 'company' ],
                sortCol: 'total_article_amount',
                sortDir: 'DESC',
                filters: exclusions.map(filter => ({
                    column: 'name',
                    displayName: filter.label ?? 'onbekend',
                    value: filter.key!
                }))
            } }
            // forms={ {
            // } }
        />
    </>
    );
};

export default OverviewTable;
